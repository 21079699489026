<template>
  <div class="address-input mb-2">
    <div class="address-input__select">
      <single-select
        name="sale_house[town_id]"
        :options="townOptions"
        :selected="townId"
        @select="selectTownId"
        @remove="removeTownId"
      />
    </div>
    <input
      type="text"
      name="sale_house[address]"
      :value="currentAddress"
      class="address-input__text form-control"
    />
  </div>
  <div class='resource-form__input'>
    <div>※ 該当の小学校区、中学校区がない場合は、お手数ですが家づくりナビ編集部までご連絡ください。</div>
    <div class="d-flex align-items-center mb-2">
      <label class="me-2 text-nowrap">
        小学校区
      </label>
      <single-select
        ref="elementarySchoolSelect"
        name="sale_house[elementary_school_id]"
        :options="elementarySchoolOptions"
        :selected="currentElementarySchoolId"
      />
    </div>
    <div class="d-flex align-items-center">
      <label class="me-2 text-nowrap">
        中学校区
      </label>
      <single-select
        ref="juniorHighSchoolSelect"
        name="sale_house[junior_high_school_id]"
        :options="juniorHighSchoolOptions"
        :selected="currentJuniorHighSchoolId"
      />
    </div>
  </div>

</template>

<script lang="ts">
import SingleSelect from '../../../common/components/SingleSelect.vue'

interface Option { label: string, value: string|number }

export default {
  components: { SingleSelect },
  props: {
    townOptions: {
      type: Object,
      required: true
    },
    townId: {
      type: Number,
      default: null
    },
    address: {
      type: String,
      default: null
    },
    groupedElementarySchoolOptions: {
      type: Object,
      required: true
    },
    elementarySchoolId: {
      type: Number,
      default: null
    },
    groupedJuniorHighSchoolOptions: {
      type: Object,
      required: true
    },
    juniorHighSchoolId: {
      type: Number,
      default: null
    }
  },
  data (): { currentTownId: number, currentAddress: string, currentElementarySchoolId: number, currentJuniorHighSchoolId: number } {
    return {
      currentTownId: this.townId,
      currentAddress: this.address,
      currentElementarySchoolId: this.elementarySchoolId,
      currentJuniorHighSchoolId: this.juniorHighSchoolId
    }
  },
  computed: {
    elementarySchoolOptions (): { [key: string]: string|number } {
      return this.groupedElementarySchoolOptions[this.currentTownId] || {}
    },
    juniorHighSchoolOptions (): { [key: string]: string|number } {
      return this.groupedJuniorHighSchoolOptions[this.currentTownId] || {}
    }
  },
  methods: {
    selectTownId (selectedOption: Option): void {
      this.currentTownId = selectedOption.value
      this.clearSchools()
    },
    removeTownId (): void {
      this.currentTownId = null
      this.clearSchools()
    },
    clearSchools (): void {
      this.$refs.elementarySchoolSelect?.clear()
      this.$refs.juniorHighSchoolSelect?.clear()
    }
  }
}
</script>
