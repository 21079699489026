import { Magazine } from './magazine'

/**
 * 媒体一覧を取得
 * TODO: dataはqrcode-generator側のAPIが整備されるまでの仮のもの
 */
export class MagazineFetcher {
  /**
   * @returns {Array}
   */
  data () {
    return [
      {
        id: 1,
        name: '金沢情報',
        param: 'R=KJ'
      },
      {
        id: 2,
        name: '富山情報',
        param: 'R=TJ'
      },
      {
        id: 3,
        name: '新潟情報',
        param: 'R=NJ'
      },
      {
        id: 4,
        name: '高岡情報',
        param: 'R=OJ'
      },
      {
        id: 5,
        name: '家づくりナビ',
        param: 'R=mag'
      },
      {
        id: 6,
        name: '結婚SANKA',
        param: 'R=QR'
      },
      {
        id: 7,
        name: 'Tateta',
        param: 'R=tateta'
      },
      {
        id: 8,
        name: 'LunchFan',
        param: 'R=LunchFan'
      },
      {
        id: 9,
        name: 'チラシ',
        param: 'R=Leaflet'
      },
      {
        id: 10,
        name: 'ファミール',
        param: 'R=fa'
      }
    ]
  }

  /**
   * @returns {Promise<Array>}
   */
  fetch () {
    return Promise.resolve(this.data().map(mag => new Magazine(mag)))
  }
}
