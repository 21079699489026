/**
 * QRコードファイル名生成
 *  - Rule : QR_IN_{name}_{id}_{pageType}_{id}_{magazine}.png
 */
export class QrcodeFilename {
  /**
   * @returns {string}
   */
  get prefix () { return 'QR_IN' }
  /**
   * @returns {string}
   */
  get ext () { return 'png' }
  /**
   * @returns {object}
   */
  get companyAccount () { return this._companyAccount }
  /**
   * @returns {string}
   */
  get page () { return this._page }
  /**
   * @returns {string}
   */
  get magazine () { return this._magazine }
  /**
   * @returns {string}
   */
  get issued () { return this._issued }

  /**
   * @param {object} params
   * @param {object} params.companyAccount
   * @param {object} params.page
   * @param {string} params.magazine
   * @param {string} params.issued
   */
  constructor ({ companyAccount, page, magazine, issued }) {
    this._companyAccount = companyAccount
    this._page = page
    this._magazine = magazine
    this._issued = issued
  }

  /**
   * @returns {string}
   */
  baseName () {
    return [
      this.prefix,
      this.companyAccount.id,
      this.companyAccount.name,
      this.page.name,
      this.page.resource.id,
      this.magazine,
      this.issued
    ].filter(v => v).join('_')
  }

  /**
   * @returns {string}
   */
  toString () {
    return [
      this.baseName(),
      this.ext
    ].join('.')
  }
}
