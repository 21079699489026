<template>
  <div>{{`※ 画像の容量は${(resourceName.match(/^(land|model_house|sale_house)/)) ? 2 : 10}MB以内で、jpgまたはpngのみアップロードできます`}}</div>
  <div v-for="(resource, key) in resources" :key="resource.key">
    <multiple-image-field
      :index = "key"
      :imageResource = "resource"
      :namePrefix = "resourceName"
      :options = "options"
      :captionEnabled="captionEnabled"
      :bodyEnabled="bodyEnabled"
    ></multiple-image-field>
  </div>
</template>

<script lang="ts">
import MultipleImageField from '../common/MultipleImageField.vue'

export default {
  created (): void {
    const sampleResources = [...Array(this.totalResourceCount - this.givenResources.length)].map(() => (
      { id: '', caption: '', body: '', position: '', previewImage: '', remove_image: '', _destroy: false }
    ))

    this.resources = this.givenResources.concat(sampleResources)
    this.options = [...Array(this.totalResourceCount)].map((_, i) => ({ id: i + 1, value: i + 1 }))
  },
  components: { MultipleImageField },
  props: {
    givenResources: {
      type: Array,
      required: true
    },
    resourceName: {
      type: String,
      required: true
    },
    totalResourceCount: {
      type: Number,
      required: true
    },
    captionEnabled: {
      type: Boolean,
      default: false
    },
    bodyEnabled: {
      type: Boolean,
      default: false
    }
  },
  data (): { resources: string[], options: string[]} {
    return {
      resources: [],
      options: []
    }
  }
}
</script>
