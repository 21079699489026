/**
 * 媒体
 */
export class Magazine {
  /**
   * @returns {number}
   */
  get id () { return this._id }
  /**
   * @returns {string}
   */
  get name () { return this._name }
  /**
   * @returns {string}
   */
  get param () { return this._param }
  /**
   * @param {object} params
   * @param {number} params.id
   * @param {string} params.name
   * @param {string} params.param
   */
  constructor ({ id, name, param }) {
    this._id = id
    this._name = name
    this._param = param
  }
}
