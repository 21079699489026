<template>
  <transition name="fade">
    <div
      class="overlay"
      @click.self="$emit('close')"
    >
      <div class="widget">
        <div class="page-header border-bottom border-dark">
          <div class="row">
            <h1 class="col fs-4">
              【QRコード発行】
              <span class="fs-5">
                {{ companyAccount.name }}
              </span>
            </h1>
          </div>
          <div
            class="widget__close-button"
            @click="$emit('close')"
          >
            ×
          </div>
        </div>
        <div class="widget__content">
          <section class="mb-4">
            <qrcode-form
              :magazines="magazines"
              :change="magazines"
              v-model:model="model"
            />
          </section>

          <section
            class="container widget__content__images"
            v-if="model.magazine"
          >
            <div
              class="row row-cols-2"
            >
              <div
                class="col"
                v-for="(page, index) in pages"
                :key="index"
              >
                <qrcode-image
                  :companyAccount="companyAccount"
                  :page="page"
                  :model="model"
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { MagazineFetcher } from './magazine-fetcher'
import { QrcodeFormModel } from './qrcode-form-model'
import QrcodeImage from './QrcodeImage.vue'
import QrcodeForm from './QrcodeForm.vue'

export default {
  name: 'QrcodeWidget',
  components: {
    QrcodeImage,
    QrcodeForm
  },
  props: [
    'companyAccount',
    'pages'
  ],
  /**
   * @returns {object}
   */
  data () {
    return {
      magazines: [],
      model: new QrcodeFormModel({})
    }
  },
  /**
   * mounted
   *  - fetch magazine list
   */
  async mounted () {
    const fetcher = new MagazineFetcher()
    this.magazines = await fetcher.fetch()
  }
}
</script>

<style lang='scss' scoped>
.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.widget {
  position: relative;
  z-index: 2;
  width: 95vw;
  max-width: 640px;
  height: 90%;
  max-height: 720px;
  padding: 20px;
  background: white;
  border-radius: 12px;
  overflow: auto;

  .widget__content {
    padding: 10px 20px;

    .widget__content__images {
      height: 95%;
      overflow: auto;
    }
  }
}

.widget__close-button {
  position: absolute;
  top: 8px;
  right: 24px;
  margin: 0;
  color: #707070;
  font-size: 40px;
  cursor: pointer;
  line-height: 40px;
}
</style>
