<template>
  <span class="small">
    ※「メインビジュアル」にチェックを入れると、メインビジュアルの画像一覧に表示されます<br>
    ※「間取り図」を選択すると、ページ内の間取り図部分に表示されます<br>
    ※「区画図」を選択すると、ページ内の区画図部分に表示されます<br>
    ※「周辺環境」を選択すると、ページ内の周辺情報・おすすめポイント部分に表示されます
  </span>
  <sale-house-image-form-input
    v-for="image in imageArray"
    :key="image.index"
    :image="image"
    :categories="categories"
    :positions="positions"
  />
  <label class="btn btn-outline-secondary">
    ファイルを選択
    <input
      type="file"
      ref="image"
      @change="add"
      style="display: none"
    >
  </label>
</template>

<script lang="ts">
import axios from 'axios'
import SaleHouseImageFormInput from './SaleHouseImageFormInput.vue'

export default {
  components: { SaleHouseImageFormInput },
  props: {
    categories: {
      type: Object,
      required: true
    },
    resourceId: {
      type: Number,
      required: true
    },
    maxNumber: {
      type: Number,
      required: true
    },
    images: {
      type: Array,
      default: () => [] // eslint-disable-line jsdoc/require-jsdoc
    }
  },
  data (): { imageArray: Object[], dirty: boolean } {
    return {
      imageArray: this.images.map((image, index) => Object.assign(image, { index: index })),
      dirty: false
    }
  },
  created (): void {
    window.addEventListener('beforeunload', this.beforeUnloadHandler)
    document.querySelectorAll('input[type="submit"]').forEach((el) => {
      el.addEventListener('click', () => {
        window.removeEventListener('beforeunload', this.beforeUnloadHandler)
      })
    })
  },
  beforeUnmount (): void {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler)
  },
  computed: {
    positions (): number[] {
      return [...Array(this.maxNumber)].map((_, i) => (i + 1))
    },
    nextIndex (): number {
      if (this.imageArray.length === 0) {
        return 0
      }
      return this.imageArray.map((image: { index: number }) => image.index).reduce((a: number, b: number) => Math.max(a, b)) + 1
    }
  },
  methods: {
    add (): void {
      const formData = new FormData()
      formData.append('sale_house[image]', this.$refs.image.files[0])

      axios.post(`/${location.pathname.split('/')[1]}/sale_houses/${this.resourceId}/images`, formData)
        .then((response: {}) => {
          this.imageArray.push(Object.assign(response.data, { index: this.nextIndex }))
        })
        .catch((error: {}) => {
          alert(error.response.data)
        })
        .finally(() => {
          this.$refs.image.value = ''
        })

      this.dirty = true
    },
    beforeUnloadHandler (e): void {
      if (!this.dirty) return
      e.preventDefault()
      e.returnValue = ''
    }
  }
}
</script>
