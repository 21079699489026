<template>
  <select class="form-select" v-model="yearValue">
    <option value="" />
    <option v-for="i in yearOptionCount" :value="startYear + i - 1" :key="i">
      {{ startYear + i - 1 }}
    </option>
  </select>
  <select class="form-select" v-model="monthValue">
    <option value="" />
    <option v-for="i in 12" :value="i" :key="i">
      {{ i }}
    </option>
  </select>
  <input type="hidden" :name="name" :value="dateValue" />
</template>

<script lang="ts">
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    date: {
      type: String,
      default: ''
    },
    startYear: {
      type: Number,
      default: (new Date()).getFullYear() - 5
    },
    endYear: {
      type: Number,
      default: (new Date()).getFullYear() + 5
    }
  },
  data (): { yearOptionCount: number, yearValue: number, monthValue: number } {
    const date: Date = (this.date !== '') ? new Date(this.date) : null

    return {
      yearOptionCount: this.endYear - this.startYear + 1,
      yearValue: date?.getFullYear(),
      monthValue: date ? date.getMonth() + 1 : null
    }
  },
  computed: {
    dateValue (): string {
      if (!this.yearValue || !this.monthValue) {
        return ''
      }

      const date: Date = new Date(this.yearValue, this.monthValue - 1)
      const year: number = date.getFullYear()
      const month: string = ('00' + (date.getMonth() + 1)).slice(-2)
      const day: string = ('00' + date.getDate()).slice(-2)
      return year + '-' + month + '-' + day
    }
  }
}
</script>

<style lang="scss" scoped>
.form-select {
  display: inline;
  width: auto;
}
</style>
