<template>
  <div>
    <input type='hidden' :name="name"  value=''>
    <label>
      <input
        type='radio'
        :name="name"
        v-model="selectedForm"
        @change="switchForm()"
        value='new_built'
        class='form-check-input'
        style='margin: 5px;'
      >
      {{ options.new_built }}
    </label>
    <label>
      <input
        type='radio'
        :name="name"
        v-model="selectedForm"
        @change="switchForm()"
        value='renovated'
        class='form-check-input'
        style='margin: 5px;'
      >
      {{ options.renovated }}
    </label>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'

interface BuildingKindOption {
  new_built: string // t('building_kind')
  renovated: string // t('building_kind')
}

export default {
  /**
   * @returns {string} - 建築実例オブジェクトにおける「種類`building_kind`」を返す。新規登録時：nil, 既存データ修正時："new_built" or "renovated"
   */
  data () {
    return {
      selectedForm: this.defaultSelectedForm
    }
  },
  props: {
    name: {
      type: String,
      required: true
    },
    options: {
      type: Object as PropType<BuildingKindOption>,
      required: true
    },
    defaultSelectedForm: {
      type: String,
      required: true
    },
    formKlasses: {
      type: Object,
      required: true
    }
  },
  /**
   *
   */
  mounted (): void {
    this.switchForm()
  },
  methods: {
    /**
     * 新築用/リノベーション用フォームの表示切り替え
     *
     */
    switchForm () {
      this.allInputDisplayNone()
      const els = document.getElementsByClassName(this.formKlasses[this.selectedForm])
      Array.prototype.forEach.call(els, function (e) {
        e.style.display = 'flex'
      })
    },
    /**
     * 一旦全て非表示にする
     */
    allInputDisplayNone () {
      Object.keys(this.formKlasses).forEach((key: string) => {
        const els = document.getElementsByClassName(this.formKlasses[key])
        Array.prototype.forEach.call(els, function (e) {
          e.style.display = 'none'
        })
      })
    }
  }
}
</script>
