<template>
  <div class='row align-items-start mt-2' style="width: 750px">
    <div class='col-3'>
      <label class="col align-items-start btn btn-outline-secondary" style="display: block">
        ファイルを選択
        <input
          type="file"
          ref="image"
          :name="namePrefixWithIndex + '[image]'"
          @change="preparedPreview($event)"
          style="display: none"
        >
      </label>
      <img
        :src="preview()"
        v-show="preview()"
        height="100"
        width="150"
        class="col align-items-end my-2 mx-auto"
      >
      <input
        type="hidden"
        :name="namePrefixWithIndex + '[image_cache]'"
        :value="resource['image_cache']"
      >
      <input
        type="hidden"
        :name="namePrefixWithIndex + '[remove_image]'"
        :value="resource['remove_image']"
      >
    </div>
    <div class='col-5'>
      <div class='align-items-start mb-1' v-if="this.captionEnabled">
        <input
          class='form-control align-items-start'
          type="text"
          :name="namePrefixWithIndex + '[caption]'"
          v-model="resource['caption']"
          @change="toggleDeleteFlag()"
        >
      </div>
      <div class='align-items-end' v-if="bodyEnabled">
        <textarea
          class='form-control'
          type="text"
          :name="namePrefixWithIndex + '[body]'"
          v-model="resource['body']"
          @change="toggleDeleteFlag()"
        >
        </textarea>
      </div>
    </div>
    <div class='col-2'>
      <select
        :name="namePrefixWithIndex + '[position]'"
        v-model="resource['position']"
        @change="toggleDeleteFlag()"
        class='form-select'
        ref='position'
      >
        <option disabled value=''>並び順</option>
        <option v-for="option in options" :value="option.value" :key="option.id">
          {{ option.value }}
        </option>
      </select>
    </div>
    <div class='col-2'>
      <button type="button" class="btn btn-secondary" @click="clearResources()">クリア</button>
    </div>
    <input
      type="hidden"
      :name="namePrefixWithIndex + '[id]'"
      :value="resource['id']"
    >
    <input
      type="hidden"
      :name="namePrefixWithIndex + '[_destroy]'"
      :value="resource['_destroy']"
    >
  </div>
</template>

<script lang="ts">
export default {
  props: {
    index: {
      type: Number,
      require: true
    },
    namePrefix: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    imageResource: {
      type: Object
    },
    captionEnabled: {
      type: Boolean,
      default: false
    },
    bodyEnabled: {
      type: Boolean,
      default: false
    }
  },
  data (): { resource: string[], namePrefixWithIndex: string } {
    return {
      resource: [],
      namePrefixWithIndex: this.namePrefix + '[' + this.index + ']'
    }
  },
  mounted (): void {
    this.resource = this.imageResource
    if (!this.resource.position) { this.resource.position = this.index + 1 }
  },
  methods: {
    preparedPreview (event: { target: HTMLInputElement }): void {
      const image = event.target.files[0]
      const reader = new FileReader()

      reader.onload = (event: ProgressEvent<FileReader>): void => {
        this.resource.previewImage = event.target.result
        this.resource.remove_image = ''
        this.resource._destroy = false
      }
      reader.readAsDataURL(image)
    },
    preview (): string {
      if (this.resource.image && !this.resource.previewImage && !this.resource.remove_image) {
        return this.resource.image.url
      }
      return this.resource.previewImage
    },
    clearResources (): void {
      const clearAttribute = ['caption', 'body', 'image', 'previewImage']
      const notClearAttribute = ['remove_image', '_destroy']

      this.$refs.image.value = null
      this.$refs.position.value = ''
      clearAttribute.forEach((attribute) => { this.resource[attribute] = '' })
      notClearAttribute.forEach((attribute) => { this.resource[attribute] = true })
    },
    toggleDeleteFlag (): void {
      this.resource._destroy = false
    }
  }
}
</script>
