<template>
  <div class='resource-form__row__datepicker'>
    <div class='resource-form__row'>
      <div class='resource-form__label'>
        <label for='event_form_schedule'>
          開催日程
        </label>
        <span class='resource-form__required'>※</span>
      </div>
      <div class='resource-form__input-with-description'>
        <div class='resource-form__description'>
          ※ 開催日程をカレンダーから入力してください
        </div>
        <input
          @focus="showDatePicker"
          class='resource-form__input form-control'
          style='background-color: #fff'
          type='text'
          name="event[schedule]"
          id="event_schedule"
          v-model='days'
          readonly
        >
      </div>
    </div>
    <div class='resource-form__picker' v-if='isShown' @mouseleave="leaveDatePicker">
      <!-- NOTICE: DatePicker コンポーネントの modelValue に日付を渡すことで、渡した日付がカレンダー上でデフォルトで選択された状態になり、また基準日としても設定されます。 -->
      <!--  - 例えば、2030/10/26 を渡すとカレンダーの基準日（現在日）を 2030/10/26 に設定し、2030/10/26 が選択された状態で表示されます。 -->
      <!--  - 日付を渡さない場合は、基準日を現在の日付に設定します。ただ、現在の日付はカレンダー上では選択されていない状態になります。 -->
      <DatePicker :attributes="attributes" @dayclick="onDayClick" :modelValue="false" />
    </div>
  </div>
</template>

<script lang="ts">
import { DatePicker } from 'v-calendar'

export default {
  components: { DatePicker },
  props: {
    scheduleDates: {
      type: String,
      default: ''
    }
  },
  created (): void {
    if (this.scheduleDates === '') {
      return
    }
    this.days = this.scheduleDates.split(',')
  },
  data (): { days: string[], isShown: boolean } {
    return {
      days: [],
      isShown: false
    }
  },
  computed: {
    attributes (): { highlight: boolean, dates: string } {
      return this.days.map((day: string) => ({
        highlight: true,
        dates: day
      }))
    }
  },
  methods: {
    showDatePicker (): void {
      this.isShown = true
    },
    leaveDatePicker (): void {
      this.isShown = false
    },
    onDayClick (date: any): void {
      const selectedDay: string = date.id
      const dayIndex: number = this.days.findIndex((day: string) => day === selectedDay)

      if (dayIndex === -1) {
        this.days.push(selectedDay)
      } else {
        this.days.splice(dayIndex, 1)
      }
    }
  }
}
</script>
