<template>
  <div ref="root" style="margin-bottom: 5px">
    <input type="hidden" :name="`${namePrefix}[id]`" :value="contract.id" />
    <span ref="editableInputs">
      <span style="display: inline-block; width: 300px; margin-right: 5px">
        <span v-if="contract.id">
          {{ optionName }}
        </span>
        <single-select
          v-else
          :name="`${namePrefix}[${serviceIdAttr}]`"
          :options="options"
          :selected="serviceId"
        />
      </span>
      <span style="display: inline-block; width: 175px">
        <span v-if="contract.id">
          {{ startedOnText }}
        </span>
        <template v-else>
          <month-select
            :name="`${namePrefix}[started_on]`"
            :date="startedOn"
            :start-year=2021
          />
          <span class="resource-form__required"> ※ </span>
        </template>
      </span>
      〜
      <span>
        <month-select
          :name="`${namePrefix}[ended_on]`"
          :date="endedOn"
          :start-year=2021
        />
      </span>
    </span>
    <input type="hidden" :name="`${namePrefix}[discarded]`" :value="discarded" />
    <button class="remove-button btn btn-outline-secondary" v-on:click.prevent="remove">
      -
    </button>
  </div>
</template>

<script lang="ts">
import SingleSelect from '../../../common/components/SingleSelect.vue'
import MonthSelect from '../common/MonthSelect.vue'

export default {
  components: { SingleSelect, MonthSelect },
  props: {
    serviceKind: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    contract: {
      type: Object,
      default: () => { return {} } // eslint-disable-line jsdoc/require-jsdoc
    }
  },
  data (): { namePrefix: string, serviceIdAttr: string, id: string, serviceId: string, startedOn: string, endedOn: string, discarded: string } {
    const serviceIdAttr = `service_${this.serviceKind}_id`

    return {
      namePrefix: `company_account[service_${this.serviceKind}_contracts_attributes][${this.contract.index}]`,
      serviceIdAttr: serviceIdAttr,
      id: this.contract.id || '',
      serviceId: this.contract[serviceIdAttr] || '',
      startedOn: this.contract.started_on || '',
      endedOn: this.contract.ended_on || '',
      discarded: this.contract.discarded || ''
    }
  },
  mounted (): void {
    if (this.discarded !== '') {
      this.discard()
    }
  },
  computed: {
    optionName (): string {
      const inverseOptions = Object.keys(this.options).reduce((ret, key) => {
        ret[this.options[key]] = key
        return ret
      }, {})
      return inverseOptions[this.serviceId]
    },
    startedOnText (): string {
      const date = new Date(this.startedOn)
      return `${date.getFullYear()}/${date.getMonth() + 1}`
    }
  },
  emits: ['remove'],
  methods: {
    discard (): void {
      this.discarded = 'true'
      this.$refs.editableInputs.querySelectorAll('input, select').forEach((el: { disabled: boolean }) => { el.disabled = true })
      this.$refs.root.style.display = 'none'
    },
    remove (): void {
      if (this.contract.id) {
        this.discard()
      } else {
        this.$emit('remove', this.contract.index)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.remove-button {
  width: 36px;
  margin-left: 5px;
  vertical-align: inherit;
}
</style>
