<template>
  <div class="row align-items-start mb-2" style="width: 750px;" v-show="!removing">
    <div class="col-3">
      <img :src="image.image.url" height="100" width="150" class="align-items-end my-2 mx-auto">
    </div>
    <div class="col-7">
      <div class="row">
        <div class="col-8">
          <single-select
            :name="`${namePrefix}[sale_house_image_category_id]`"
            :options="categories"
            :selected="image.sale_house_image_category_id"
            :disabled="removing == '1'"
          />
        </div>
        <div class="col-4">
          <select
            :name="`${namePrefix}[position]`"
            :value="position"
            class='form-select'
            :disabled="removing == '1'"
          >
            <option disabled value=''>並び順</option>
            <option v-for="position in positions" :value="position" :key="position">
              {{ position }}
            </option>
          </select>
        </div>
      </div>

      <input type="hidden" :name="`${namePrefix}[main]`" value="0" :disabled="removing == '1'" />
      <label>
        <input
          type="checkbox"
          class="form-check-input"
          :name="`${namePrefix}[main]`"
          value="1"
          :checked="image.main"
          :disabled="removing == '1'"
        />
        メインビジュアル
      </label>
      <textarea
        type="text"
        class='form-control'
        :name="`${namePrefix}[body]`"
        :value="image.body"
        :disabled="removing == '1'"
      >
      </textarea>
    </div>
    <div class="col-2">
      <input type="hidden" :name="`${namePrefix}[id]`" :value="image.id" />
      <input type="hidden" :name="`${namePrefix}[remove_image]`" :value="removing" />
      <input type="hidden" :name="`${namePrefix}[_destroy]`" :value="removing" />
      <button class="remove-button btn btn-outline-secondary col" @click.prevent="remove">
        -
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import SingleSelect from '../../../common/components/SingleSelect.vue'

export default {
  components: { SingleSelect },
  props: {
    categories: {
      type: Object,
      required: true
    },
    image: {
      type: Object,
      default: () => { return {} } // eslint-disable-line jsdoc/require-jsdoc
    },
    positions: {
      type: Array,
      default: () => [] // eslint-disable-line jsdoc/require-jsdoc
    }
  },
  data (): { namePrefix: string, removing: string } {
    return {
      namePrefix: `sale_house[sale_house_images_attributes][${this.image.index}]`,
      removing: this.image.remove_image
    }
  },
  computed: {
    /**
     * サブ画像のデフォルトの「並び順」
     *
     * @returns { number }
     */
    position (): number {
      if (this.image.position) {
        return this.image.position
      }

      const maxNumber = 50 // NOTE: SaleHouseImage::MAX_NUMBER に準じる
      let existingMaxPosition = 0
      const existingPositions = Array.from(document.querySelectorAll("select[name^='sale_house[sale_house_images_attributes]']:not(:disabled)")).map(element => parseInt(element.value))

      if (existingPositions.length) {
        existingMaxPosition = Math.max(...existingPositions)
      }

      if (existingMaxPosition < maxNumber) {
        return existingMaxPosition + 1
      } else {
        return null
      }
    }
  },
  methods: {
    remove (): void {
      this.removing = '1'
    }
  }
}
</script>

<style lang="scss" scoped>
.remove-button {
  width: 36px;
  margin-left: 5px;
  vertical-align: inherit;
}
</style>
