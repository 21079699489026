/**
 * QRCodeFormModel
 */
export class QrcodeFormModel {
  /**
   * @returns {string}
   */
  get magazine () { return this._magazine }
  /**
   * @param {string} mag
   */
  set magazine (mag) { this._magazine = mag }
  /**
   * @returns {string}
   */
  get issued () { return this._issued }
  /**
   * @param {string} date
   */
  set issued (date) { this._issued = date }

  /**
   * @param {object} params
   * @param {string|undefined} params.magazine
   * @param {string|undefined} params.issued
   */
  constructor ({ magazine, issued }) {
    this.magazine = magazine
    this.issued = issued
  }
}
