import Rails from '@rails/ujs'
import { Turbo } from '@hotwired/turbo-rails'
import { Ienavi } from 'src/javascripts/common/ienavi'
import 'src/stylesheets/ienavi-admin/application'
import 'src/javascripts/common/config/axios'

// turbo drive は使用しないので無効化
Turbo.session.drive = false
Rails.start()

const context = require.context(
  'src/javascripts/',
  true,
  /^.\/(ienavi-admin|common)\/.+(?<!\.spec)\.(vue|ts)$/
)

Ienavi.start(context)
