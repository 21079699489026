<template>
  <div class='d-flex align-items-center mb-2'>
    <input
      type="text"
      v-model="m2Min"
      :name="`${namePrefix}[${name}_m2_min]`"
      class="form-control"
      @blur="calcMin"
    >
    &nbsp;〜&nbsp;
    <input
      type="text"
      v-model="m2Max"
      :name="`${namePrefix}[${name}_m2_max]`"
      class="form-control"
      @blur="calcMax"
    >
    &nbsp;(㎡)
  </div>
  <div class='d-flex align-items-center'>
    <input
      type="text"
      v-model="tsuboMin"
      :name="`${namePrefix}[${name}_tsubo_min]`"
      class="form-control"
    >
    &nbsp;〜&nbsp;
    <input
      type="text"
      v-model="tsuboMax"
      :name="`${namePrefix}[${name}_tsubo_max]`"
      class="form-control"
    >
    &nbsp;(坪)
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'

interface PriceRange {
  m2_min?: number
  m2_max?: number
  tsubo_min?: number
  tsubo_max?: number
}

export default {
  props: {
    namePrefix: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    values: {
      type: Object as PropType<PriceRange>,
      default: (): PriceRange => { return {} } // eslint-disable-line jsdoc/require-jsdoc
    }
  },
  data (): { m2Min: number, m2Max: number, tsuboMin: number, tsuboMax: number } {
    return {
      m2Min: this.values.m2_min || '',
      m2Max: this.values.m2_max || '',
      tsuboMin: this.values.tsubo_min || '',
      tsuboMax: this.values.tsubo_max || ''
    }
  },
  methods: {
    calcMin (): void {
      if (isNaN(this.m2Min)) {
        this.tsuboMin = ''
      } else {
        this.tsuboMin = this.m2Min === '' ? '' : Math.floor(this.m2Min * (0.3025 * 100)) / 100
      }
    },
    calcMax (): void {
      if (isNaN(this.m2Max)) {
        this.tsuboMax = ''
      } else {
        this.tsuboMax = this.m2Max === '' ? '' : Math.floor(this.m2Max * (0.3025 * 100)) / 100
      }
    }
  }
}
</script>
