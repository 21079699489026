<template>
  <div class='row align-items-start' style="width: 750px">
    <div>{{`※ 画像の容量は${(namePrefix === 'land' || namePrefix === 'model_house' || namePrefix === 'sale_house') ? 2 : 10}MB以内で、jpgまたはpngのみアップロードできます`}}</div>
    <div class='col-3'>
      <label class="col align-items-start btn btn-outline-secondary" style="display: block">
        ファイルを選択
        <input
          type="file"
          ref="image"
          :name="`${namePrefix}[${imageName}]`"
          @change="preparedPreview"
          style="display: none"
        >
        <input
          type="hidden"
          :name="`${namePrefix}[remove_${imageName}]`"
          v-model="deletedImage"
        >
        <input
          type="hidden"
          :name="`${namePrefix}[${imageName}_cache]`"
          :value="cacheImage"
        >
      </label>
      <img
        :src="previewImage"
        v-show="previewImage"
        height="100"
        width="150"
        class="col align-items-end my-2 mx-auto"
      >
    </div>
    <div class='col-7' v-if="captionEnabled || bodyEnabled">
      <div class='align-items-start mb-1' v-if="captionEnabled">
        <input
          class='form-control'
          type="text"
          :name="`${namePrefix}[${imageName}_caption]`"
          v-model="caption"
        >
      </div>
      <div class='align-items-end' v-if="bodyEnabled">
        <textarea
          class='form-control'
          :name="`${namePrefix}[${imageName}_body]`"
          v-model="body"
        >
        </textarea>
      </div>
    </div>
    <div class='col-2'>
      <button type="button" class="btn btn-secondary" @click="clearResources">クリア</button>
    </div>
  </div>
</template>

<script lang="ts">

interface Images {
  name: string,
  cacheImage: string,
  caption: string,
  body: string,
  previewImage: string,
}

export default {
  created (): void {
    this.cacheImage = this.cache
    this.previewImage = this.removedState === 'true' ? '' : this.givenImage
    this.caption = this.givenCaption
    this.body = this.givenBody
  },
  props: {
    namePrefix: {
      type: String,
      require: true
    },
    imageName: {
      type: String,
      require: true
    },
    givenImage: {
      type: String
    },
    cache: {
      type: String
    },
    removedState: {
      type: String
    },
    givenCaption: {
      type: String
    },
    givenBody: {
      type: String
    },
    captionEnabled: {
      type: Boolean,
      default: false
    },
    bodyEnabled: {
      type: Boolean,
      default: false
    }
  },
  data (): Images {
    return {
      name: '',
      cacheImage: '',
      caption: '',
      body: '',
      previewImage: ''
    }
  },
  computed: {
    deletedImage (): boolean {
      return !this.previewImage
    }
  },
  methods: {
    preparedPreview (event: { target: HTMLInputElement }): void {
      const image = event.target.files[0]
      const reader = new FileReader()

      reader.onload = (event: ProgressEvent<FileReader>): void => {
        this.previewImage = event.target.result
      }
      reader.readAsDataURL(image)
    },
    clearResources (): void {
      this.$refs.image.value = null
      this.caption = ''
      this.body = ''
      this.previewImage = null
    }
  }
}
</script>
