<template>
  <contract-form-input
    v-for="contract in contractArray"
    :key="contract.index"
    :service-kind="serviceKind"
    :options="options"
    :contract="contract"
    @remove="remove"
  />
  <button class="add-button btn btn-outline-secondary" v-on:click.prevent="add">
    +
  </button>
</template>

<script lang="ts">
import ContractFormInput from './ContractFormInput.vue'

export default {
  components: { ContractFormInput },
  props: {
    serviceKind: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    contracts: {
      type: Array,
      default: () => [] // eslint-disable-line jsdoc/require-jsdoc
    }
  },
  data (): { contractArray: Object[] } {
    return {
      contractArray: this.contracts.map((contract, index) => Object.assign(contract, { index: index }))
    }
  },
  computed: {
    nextIndex (): number {
      if (this.contractArray.length === 0) {
        return 0
      }
      return this.contractArray.map((contract: { index: number }) => contract.index).reduce((a: number, b: number) => Math.max(a, b)) + 1
    }
  },
  methods: {
    add (): void {
      this.contractArray.push({ index: this.nextIndex })
    },
    remove (index: number): void {
      this.contractArray.splice(this.contractArray.findIndex((contract: { index: number }) => contract.index === index), 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.add-button {
  width: 36px;
}
</style>
