<template>
  <form class="row">
    <div class="col-12 mb-2">
      <label class="form-label">
        掲載する媒体
        <span class="text-danger">
          &nbsp;※
        </span>
      </label>
      <select
        class="form-select"
        name="magazine"
        :value="form.magazine"
        @change="updateMagazine"

      >
        <option
          v-for="(magazine, index) in magazines"
          :key="index"
          :value="magazine.param"
        >
          {{ magazine.name }}
        </option>
      </select>
    </div>
    <div class="col-12 mb-2">
      <label class="form-label">
        媒体発行日
      </label>
      <date-picker
        v-model="form.issued"
        :mode="'single'"
        locale="ja"
        :formats="formats"
        :masks="masks"
        :popover="popover"
        :model-config="{ type: 'string', mask: dateFormat }"
        :min-date="startDate"
        @dayclick="updateIssued"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <input
            class="form-control bg-white"
            readonly="true"
            required="false"
            :value="inputValue"
            v-on="inputEvents"
          />
        </template>
      </date-picker>
    </div>
  </form>
</template>

<script>
import { DatePicker } from 'v-calendar'

export default {
  name: 'QrcodeForm',
  components: {
    DatePicker
  },
  props: [
    'magazines',
    'model'
  ],
  /**
   * @returns {object}
   */
  data () {
    return {
      form: this.model,
      inputValue: undefined,
      startDate: new Date(),
      formats: {
        weekdays: 'W',
        navMonths: 'MMM',
        dayPopover: 'WWW, MMM D, YYYY',
        input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
        data: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD']
      },
      dateFormat: 'YYYY-MM-DD',
      masks: {
        title: 'YYYY MMM',
        input: ''
      },
      popover: { visibility: 'click' }
    }
  },
  /**
   * created
   *  - initialize v-calendar masks
   */
  created () {
    this.masks.input = this.dateFormat
  },
  methods: {
    /**
     * @param {object} e
     */
    updateMagazine (e) {
      this.$emit('update:model', Object.assign(this.form, { magazine: e.target.value }))
    },
    /**
     * クリックされた日付が開始日より前だったら値は更新しない
     * @param {object} e
     */
    updateIssued (e) {
      if (this.startDate > e.date) { return }
      this.$emit('update:model', Object.assign(this.form, { issued: e.id }))
    }
  }
}
</script>
