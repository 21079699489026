/**
 * QRCode Simple Generator からQRコードを取得
 *  - https://github.com/colorfulcompany/qrcode-simple-generator
 */
export class QrcodeRequestSrcGenerator {
  /**
   * @returns {string}
   */
  get endpoint () { return 'https://simple-qrcode-generator.herokuapp.com/nwjbhdunck.json' }
  /**
   * @returns {string}
   */
  get url () { return this._url }
  /**
   * @returns {string}
   */
  get fileName () { return this._fileName }

  /**
   * @param {object} params
   * @param {string} params.url
   * @param {string} params.fileName
   */
  constructor ({ url, fileName }) {
    this._url = url
    this._fileName = fileName
  }

  /**
   * @returns {object}
   */
  searchParams () {
    return {
      chl: this.url,
      filename: this.fileName,
      attachment: true,
      chs: '250x250',
      cht: 'qr'
    }
  }

  /**
   * @returns {string}
   */
  src () {
    const qrcode = new URL(this.endpoint)
    const params = this.searchParams()
    Object.keys(params).forEach(k => {
      qrcode.searchParams.set(k, params[k])
    })

    return qrcode.href
  }
}
