<template>
  <div>
    <input type='hidden' :name="name"  value=''>
    <input
      type='radio'
      :name="name"
      :checked="newlyBuilt"
      @change="SwitchFormFor('new')"
      value='true'
      id='sale_house_newly_built_true'
      class='form-check-input'
      style='margin: 5px;'
    >
    <label for='sale_house_newly_built_true'>{{ options.true }}</label>
    <input
      type='radio'
      :name="name"
      :checked="newlyBuilt === false"
      @change="SwitchFormFor('old')"
      value='false'
      id='sale_house_newly_built_false'
      class='form-check-input'
      style='margin: 5px;'
    >
    <label for='sale_house_newly_built_false'>{{ options.false }}</label>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'

interface NewlyBuiltOption {
  true: string // t('newly_built')
  false: string // t('newly_built')
}

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    options: {
      type: Object as PropType<NewlyBuiltOption>,
      required: true
    },
    newlyBuilt: {
      type: Boolean,
      default: null
    }
  },
  /**
   *
   */
  mounted (): void {
    if (this.newlyBuilt) {
      this.SwitchFormFor('new')
    } else if (this.newlyBuilt === false) {
      this.SwitchFormFor('old')
    } else {
      /* nothing to do */
    }
  },
  methods: {
    /**
     * 新築用/中古用フォームの表示切り替え
     *
     * @param builtType
     */
    SwitchFormFor (builtType: ('new' | 'old')) {
      const elements = document.getElementsByClassName('resource-form__row')
      Array.prototype.forEach.call(elements, function (element) {
        element.style.display = 'flex'
      })

      const hiddenElements = document.getElementsByClassName(`inactive-${builtType}`)

      Array.prototype.forEach.call(hiddenElements, function (element) {
        element.style.display = 'none'
      })
    }
  }
}
</script>

<style lang="scss">
#js-admin__sale_houses__form {
  .resource-form__row {
    display: none;

    &.active-default {
      display: flex;
    }
  }
}
</style>
