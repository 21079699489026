<template>
  <div class="mb-4 p-2 border border-dark">
    <div class="row mb-2">
      <div class="col fs-6">
        {{ page.name }}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col qrcode__url">
        {{ urlWithQuery || '媒体を選択してください' }}
      </div>
    </div>

    <a
      :href="src"
      :change="src"
      download
    >
      <img
        class="w-100 h-auto mx-auto"
        width="250"
        height="250"
        :src="src"
        :change="src"
      />
    </a>
  </div>
</template>

<script>
import { QrcodeRequestSrcGenerator } from './qrcode-request-src-generator'
import { QrcodeFilename } from './qrcode-filename'

export default {
  props: [
    'companyAccount',
    'page',
    'model'
  ],
  computed: {
    /**
     * @returns {string}
     */
    query () {
      return [
        this.model.magazine,
        this.issued()
      ].filter(v => v).join('&')
    },
    /**
     * @returns {string}
     */
    urlWithQuery () {
      return [this.page.url, this.query].join('?')
    },
    /**
     * @returns {string}
     */
    src () {
      return this.updateSrc(this.urlWithQuery)
    }
  },
  methods: {
    /**
     * @param {string} url
     * @returns {string}
     */
    updateSrc (url) {
      const generator = new QrcodeRequestSrcGenerator({
        url: url,
        fileName: (new QrcodeFilename({
          companyAccount: this.companyAccount,
          page: this.page,
          magazine: this.model.magazine.split('=').pop(),
          issued: this.model.issued
        })).toString()
      })

      return generator.src()
    },
    /**
     * 媒体発行日のパラメータを組み立て
     *
     * @returns {string|undefined}
     */
    issued () {
      return this.model.issued
        ? `issued=${this.model.issued.replace(/-/g, '')}`
        : this.model.issued
    }
  }
}
</script>

<style lang='scss' scoped>
img {
  max-width: 200px;
  max-height: 200px;
}

.qrcode__url {
  font-size: 0.8em;
  color: grey;
}
</style>
