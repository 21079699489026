<template>
  <button
    class="issue-button"
    @click="open"
  >
    QRコード発行
  </button>
  <transition name="fade">
    <QrcodeWidget
      v-if="isOpen"
      @close="close"
      :companyAccount="companyAccount"
      :pages="pages"
    />
  </transition>
</template>

<script>
import QrcodeWidget from './QrcodeWidget.vue'

export default {
  name: 'QrcodeButton',
  components: { QrcodeWidget },
  props: [
    'companyAccount',
    'pages'
  ],
  /**
   * @returns {object}
   */
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    /**
     * widget open
     */
    open () {
      this.isOpen = true
    },
    /**
     * widget close
     */
    close () {
      this.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.issue-button {
  color: #222;
  background-color: snow;
  font-size: 13px;
  font-weight: bolder;
  border: 1px solid;
  border-radius: 5px;
  margin-left: 10px;
  padding: 5px 20px;
}
</style>
